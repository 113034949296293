@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Landed by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Loader */

	body.landing {
		&:before, &:after {
			display: none !important;
		}
	}

/* Icon */

	.icon {
		&.alt {
			color: inherit !important;
		}

		&.major {
			&.alt {
				&:before {
					color: _palette(fg-bold) !important;
				}
			}
		}
	}

/* Banner */

	#banner {
		&:after {
			background-color: _palette(bg-transparent);
		}
	}

/* Footer */

	#footer {
		.icons {
			.icon {
				&.alt {
					&:before {
						color: _palette(fg-bold) !important;
					}
				}
			}
		}
	}